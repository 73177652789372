import { combineReducers } from 'redux';
import { customerReducer as customerPage } from './../api/customer/customerReducer';
import { propertyReducer as propertyPage } from './../api/property/propertyReducer';
import { bookingReducer as bookingPage } from './../api/booking/bookingReducer';
import { cityReducer as cityPage } from '../api/cityes/cityesReducer';
import { listPropertyReducer as listPropertyPage } from '../api/listing-property/list-propertyReducer';
import { contactUsReducer as contactUsPage } from '../api/contact-us/contact-usReducer';
import { tagReducer as tagPage } from '../api/tag/tagReducer';
import { pageReducer as pagePage } from '../api/pages/pageReducer';


const appReducer = combineReducers({
    propertyPage,
    customerPage,
    bookingPage,
    cityPage,
    listPropertyPage,
    contactUsPage,
    tagPage,
    pagePage
});

export default appReducer;