import * as actionType from "./bookingActionType";

const initialState = {
  isLoading: false,
  booking: null,
  bookingConfirm: false,
  bookings: [],
  bookingAvailable: false,
  prices: 0
};

export const bookingReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionType.CREATE_BOOKING:
            return {
                ...state,
                isLoading: true
            };
        case actionType.CREATE_BOOKING_FAILURE:
            return {
                ...state,
                isLoading: false
            };
        case actionType.CREATE_BOOKING_SUCCESS:
            return {
                ...state,
                isLoading: false,
                booking: action.payload ? action.payload.data : {}
            };
        case actionType.CONFIRM_BOOKING:
            return {
                ...state,
                isLoading: true,
                bookingConfirm: false
            };
        case actionType.CONFIRM_BOOKING_FAILURE:
            return {
                ...state,
                isLoading: false
            };
        case actionType.CONFIRM_BOOKING_SUCCESS:
            return {
                ...state,
                isLoading: false,
                bookingConfirm: true
            };
        case actionType.CHECK_BOOKING:
            return {
                ...state,
                isLoading: true,
                bookingAvailable: false
            };
        case actionType.CHECK_BOOKING_FAILURE:
            return {
                ...state,
                isLoading: false
            };
        case actionType.CHECK_BOOKING_SUCCESS:
            return {
                ...state,
                isLoading: false,
                bookingAvailable: true
            };
        case actionType.FETCH_BOOKING:
            return {
                ...state,
                isLoading: true
            };
        case actionType.FETCH_BOOKING_FAILURE:
            return {
                ...state,
                isLoading: false
            };
        case actionType.FETCH_BOOKING_SUCCESS:
            return {
                ...state,
                isLoading: false,
                bookings: action.payload ? action.payload.data : false
            };
        case actionType.GET_PRICES:
            return {
                ...state,
                isLoading: true,
                prices: 0
            };
        case actionType.GET_PRICES_FAILURE:
            return {
                ...state,
                isLoading: false
            };
        case actionType.GET_PRICES_SUCCESS:
            return {
                ...state,
                isLoading: false,
                prices: action.payload ? action.payload.data : []
            };
        case actionType.SAVE_PROPERTY_FOR_NEXT:
            return {
                ...state,
                isLoading: false,
                savedProperty: action.payload ? action.payload.data : null
            };
        case actionType.REMOVE_PROPERTY_FOR_NEXT:
            return {
                ...state,
                isLoading: false,
                savedProperty: null,
                booking: null,
                bookingConfirm: false,
                bookingAvailable: false
            };
        default:
            return state;
    }
}