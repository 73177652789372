import *  as ActionTypes from "./pageActionType";

const initialState = {
    isLoading: false,
    page: {}
};

export const pageReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.GET_PAGE:
            return {
                ...state,
                isLoading: true,
            }
        case ActionTypes.GET_PAGE_FAILURE:
            return {
                ...state,
                isLoading: false,
            }
        case ActionTypes.GET_PAGE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                page: action?.payload?.data || {}
            }
        default:
            return state
    }
}