import React from 'react';
import { Box, Grid, Typography, Container } from '@mui/material'
import { Link, useNavigate } from 'react-router-dom';

const Footer = () => {
  const navigate = useNavigate();
  return (
    <Box sx={{ backgroundColor: 'secondary.main', color: '#fff' }}>
      <Container maxWidth='xl'>
        <Grid container spacing={2}  >
          <Grid item md={4} xs={12} >
            <Box sx={{ py: { md: '2.5rem', xs: '1rem' } }}>
              <Typography variant='h6'>Find Us Here</Typography>
              <Box pt={'15px'} sx={{ fontWeight: 'fontWeightLight', fontSize: '14px' }}>
                <Typography my={'10px'} variant='subtitle2'>#801-804 B-Wing, Mittal tower, Ashok Nagar, Bangalore, Karnataka 560001</Typography>
                <Typography my={'10px'} variant='subtitle2'>info@jadecaps.com</Typography>
                <Typography my={'10px'} variant='subtitle2'>+91 80 41145233</Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item md={4} xs={12}>
            <Box sx={{ py: { md: '2.5rem', xs: '1rem' } }}>
              <Typography variant='h6'>Important Links</Typography>
              <Box pt={'15px'} sx={{ fontWeight: 'fontWeightLight', fontSize: '14px' }}>
                <Typography my={'10px'} sx={{ cursor: "pointer" }} variant='subtitle2' onClick={() => navigate('/contact-us')}>Contact Us</Typography>
                <Typography my={'10px'} sx={{ cursor: "pointer" }} variant='subtitle2' onClick={() => navigate('/partner-with-us')}>Partner With Us</Typography>
                <Typography my={'10px'} sx={{ cursor: "pointer" }} variant='subtitle2' onClick={() => navigate('/extended-stays')}>Extended Stays</Typography>
                <Typography my={'10px'} sx={{ cursor: "pointer" }} variant='subtitle2'>Blog</Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item md={4} xs={12}>
            <Box sx={{ py: { md: '2.5rem', xs: '1rem' } }}>
            <Typography variant='h6'>Policies</Typography>
            <Box pt={'15px'} sx={{ fontWeight: 'fontWeightLight', fontSize: '14px' }}>
              <Typography my={'10px'} sx={{ cursor: "pointer" }} variant='subtitle2' onClick={() => navigate('/page/refund-cancellation-policy')}>Refund & Cancellation Policy</Typography>
              <Typography my={'10px'} sx={{ cursor: "pointer" }} variant='subtitle2' onClick={() => navigate('/page/terms-and-conditions')}>Terms & Conditions</Typography>
              <Typography my={'10px'} sx={{ cursor: "pointer" }} variant='subtitle2' onClick={() => navigate('/page/privacy-policy')}>Privacy Policy</Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Container>
    </Box >
  )
}

export default Footer

