import React, { lazy, Suspense } from 'react';
import { Routes, Route } from 'react-router-dom';
import { ProtectedRoute } from './App/protected.route';
const LoginContainer = lazy(() => import('./views/login/login.container'));
const HomeContainer = lazy(() => import('./views/home/home.container'));
const SignupContainer = lazy(() => import('./views/signup/signup.container'));
const PropertyDetailPage = lazy(() => import('./views/property-detail/property-detail.container'));
const PropertyListPage = lazy(() => import('./views/property-list/property-list.container'));
// const ForgetPasswordPage = lazy(() => import('./views/forget-password/forget-password.container'));
const MyBookingContainer = lazy(() => import('./views/my-booking/my-booking.container'));
const PaymentSuccessContainer = lazy(() => import('./views/payment-success/payment-success.container'));
const RequestBookingContainer = lazy(() => import('./views/request-booking/request-book.container'));
const ListYourpropertyContainer = lazy(() => import('./views/list-your-property/list-property.container'))
const ContactUsContainer = lazy(() => import('./views/contactUs/contactUs.container'))
const ExtendStaysContainer = lazy(() => import('./views/extended-stays/extended-stays.container'))
const AboutUsContainer = lazy(() => import('./views/about-us/about-us.container'));
const LandlordContainer = lazy(() => import('./views/landlords/landlord.container'));
const PartnerWithUsContainer = lazy(() => import('./views/partner-with-us/partner-with-us.container'));
const ProfileContainer = lazy(() => import('./views/profile/profile.container'));
const PropertySearcContainer = lazy(() => import('./views/property-search/property-search.container'));
const PageContainer = lazy(() => import('./views/page/page.container'));


export const RoutePath = () => (
  <Routes>
    <Route path="/" element={<Suspense fallback={<>...</>}><HomeContainer /></Suspense>} />
    <Route path="/my-booking" element={<Suspense fallback={<>...</>}><ProtectedRoute><MyBookingContainer /></ProtectedRoute></Suspense>} />
    <Route path="/payment-success" element={<Suspense fallback={<>...</>}><PaymentSuccessContainer /></Suspense>} />
    <Route path="/request-booking/:id" element={<Suspense fallback={<>...</>}><RequestBookingContainer /></Suspense>} />
    <Route path="/login" element={<Suspense fallback={<>...</>}><LoginContainer /></Suspense>} />
    <Route path="/signup" element={<Suspense fallback={<>...</>}><SignupContainer /></Suspense>} />
    <Route path="/property/:id" element={<Suspense fallback={<>...</>}><PropertyDetailPage /></Suspense>} />
    <Route path="/properties" element={<Suspense fallback={<>...</>}><PropertyListPage /></Suspense>} />
    <Route path="/list-your-property" element={<Suspense fallback={<>...</>}><ListYourpropertyContainer /></Suspense>} />
    <Route path="/contact-us" element={<Suspense fallback={<>...</>}><ContactUsContainer /></Suspense>} />
    <Route path="/extended-stays" element={<Suspense fallback={<>...</>}><ExtendStaysContainer /></Suspense>} />
    <Route path="/about-us" element={<Suspense fallback={<>...</>}><AboutUsContainer /></Suspense>} />
    <Route path="/landlord" element={<Suspense fallback={<>...</>}><LandlordContainer /></Suspense>} />
    <Route path="/partner-with-us" element={<Suspense fallback={<>...</>}><PartnerWithUsContainer /></Suspense>} />
    <Route path="/profile" element={<Suspense fallback={<>...</>}><ProfileContainer /></Suspense>} />
    <Route path="/property-search/:city/:checkIn/:checkOut/:guest" element={<Suspense fallback={<>...</>}><PropertySearcContainer /></Suspense>} />
    <Route path="/page/:slug" element={<Suspense fallback={<>...</>}><PageContainer /></Suspense>} />
    {/* <Route path="/forget-password" element={<Suspense fallback={<>...</>}><ForgetPasswordPage /></Suspense>} /> */}
  </Routes>
);
